<template>
  <q-page class="q-pa-sm">
    <div class="row">
      <div class="col-12">
        <q-item>
          <q-input v-model="entity.state.categories.editItem.name" class="full-width" clearable dense
                   label="Имя пользователя" outlined/>
        </q-item>
      </div>
      <div class="col-12">
        <q-item>
          <q-input v-model="entity.state.categories.editItem.description" class="full-width" clearable dense
                   label="Комментарий"
                   outlined/>
        </q-item>
      </div>
      <!--      <div class="col-12">-->
      <!--        <q-item>-->
      <!--          <q-input v-model="entity.state.categories.editItem.rating" class="full-width" dense label="Рейтинг" max="5"-->
      <!--                   min="1" outlined-->
      <!--                   type="number">-->
      <!--          </q-input>-->
      <!--        </q-item>-->
      <!--      </div>-->
      <!--      <div class="col-12">-->
      <!--        <q-item><b class="date_title">Дата отзыва: {{ $filters.toHumanDate(entity.state.categories.editItem.date) }}</b>-->
      <!--          <q-btn class="q-ml-lg" icon="edit" round size="sm">-->
      <!--            <q-popup-proxy cover transition-hide="scale" transition-show="scale">-->
      <!--              <q-date v-model="entity.state.categories.editItem.date" mask="YYYY-MM-DD">-->
      <!--                <div class="row items-center justify-end q-gutter-sm">-->
      <!--                  <q-btn v-close-popup color="primary" flat label="Очистить"-->
      <!--                         @click="entity.state.categories.editItem.date = null;"/>-->
      <!--                </div>-->
      <!--              </q-date>-->
      <!--            </q-popup-proxy>-->
      <!--          </q-btn>-->
      <!--        </q-item>-->
      <!--        <q-item>-->
      <!--          &lt;!&ndash;          <q-input v-model="entity.state.categories.editItem.date" class="full-width" dense&ndash;&gt;-->
      <!--          &lt;!&ndash;                   label="Дата создания"&ndash;&gt;-->
      <!--          &lt;!&ndash;                   type="date">&ndash;&gt;-->
      <!--          &lt;!&ndash;          </q-input>&ndash;&gt;-->
      <!--          &lt;!&ndash;          <q-date v-model="entity.state.categories.editItem.date" title="Дата отзыва" mask="YYYY-MM-DD">&ndash;&gt;-->
      <!--          &lt;!&ndash;            <div class="row items-center justify-end q-gutter-sm">&ndash;&gt;-->
      <!--          &lt;!&ndash;              <q-btn v-close-popup color="primary" flat label="Отменить"/>&ndash;&gt;-->
      <!--          &lt;!&ndash;              <q-btn v-close-popup color="primary" flat label="OK"/>&ndash;&gt;-->
      <!--          &lt;!&ndash;            </div>&ndash;&gt;-->
      <!--          &lt;!&ndash;          </q-date>&ndash;&gt;-->

      <!--        </q-item>-->
      <!--      </div>-->
      <!--      <div class="col-12">-->
      <!--        <q-item>-->
      <!--          <image-uploader v-if="entityId" v-model="entity.state.categories.editItem.icon_url"-->
      <!--                          :uploadMethod="file => saveImage(file)"-->
      <!--                          class="full-width"-->
      <!--                          label="Изображение для отзыва (PNG/JPEG)"/>-->
      <!--        </q-item>-->
      <!--      </div>-->
    </div>
    <div class="q-pa-md">

      <q-btn class="q-mr-md" color="primary" icon="save" label="Сохранить" rounded @click="saveCategoryEntity"/>

      <q-btn class="q-mr-md" icon="cancel" label="Назад" rounded @click="backPage"/>
    </div>
  </q-page>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { date, Notify } from 'quasar';
import { useStore } from 'vuex';
import { CategoryApi } from '@/api/apis/category-admin-api';
import ImageUploader from '@/components/forms/imageUploader';
import store from '@/store';
import { CategoryEntity } from '@/entities/category-entity';
import $filters from '../../helpers/filters';

export default defineComponent({
  computed: {
    $filters() {
      return $filters;
    },
    date() {
      return date;
    },
  },

  components: {
    ImageUploader,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    onMounted(getCategoryById);

    const entityId = ref(route.params.id);
    const entity = useStore();
    const loading = ref(false);

    async function getCategoryById() {
      if (entityId.value) {
        const response = await new CategoryApi().categoryGetItems();
        store.dispatch('updateCategories', response.data);
        const [currentCategory] = store.state.categories.data.filter((el) => (el.name === entityId.value));
        entity.dispatch('editCategoryItemUpdate', currentCategory);
      }
    }

    async function saveCategoryEntity() {
      loading.value = true;
      const category = new CategoryEntity(entity.state.categories.editItem.name, entity.state.categories.editItem.description);
      try {
        if (entityId.value) {
          const { data } = await new CategoryApi().categoryUpdate(category);
        } else {
          const { data } = await new CategoryApi().categoryCreate(category);
          entityId.value = data.id;
        }

        Notify.create({
          type: 'positive',
          message: 'Сохранено',
        });
      } catch (e) {
        console.error(e);
      }
      loading.value = false;
    }

    // async function saveImage(file) {
    //   loading.value = true;
    //   try {
    //     const {status} = await new CategoryApi().categoryUploadImage(file, entityId.value || 1)
    //     if (status === 201) {
    //       Notify.create({
    //         type: 'positive',
    //         message: 'Иконка сохранена',
    //       });
    //     }
    //   } catch (e) {
    //     console.error(e);
    //   }
    //   loading.value = false;
    // }

    const backPage = () => {
      router.back();
      entity.dispatch('editCategoryItemUpdate', null);
    };
    return {
      entity,
      CategoryApi,
      loading,
      backPage,
      saveCategoryEntity,
      entityId,
      // saveImage,
    };
  },
});
</script>
<style>
</style>
