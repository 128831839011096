import { BaseAPI } from '../base';
import { CategoryDto } from '../models';

export class CategoryApi extends BaseAPI {
  readonly localVarPath: string = '/api/2.0/category';

  public categoryGetItems() {
    return this.axiosParamCreator({
      methodApi: 'GET',
      urlMethod: this.localVarPath,
    });
  }

  public categoryCreate(body: CategoryDto) {
    return this.axiosParamCreator({
      methodApi: 'POST',
      urlMethod: `${this.localVarPath}/create`,
      body,
    });
  }

  public categoryUpdate(body: CategoryDto) {
    return this.axiosParamCreator({
      methodApi: 'PUT',
      urlMethod: `${this.localVarPath}/update`,
      body,
    });
  }

  public deleteCategory(name: CategoryDto['name']) {
    return this.axiosParamCreator({
      methodApi: 'DELETE',
      urlMethod: `${this.localVarPath}/${name}`,
    });
  }
}
